<template>
  <div
    v-if="showUpdateNotification"
    class="update-notification tw-fixed tw-bottom-[10px] tw-right-[10px] tw-z-[10000] tw-rounded-lg tw-border tw-border-calendarSuccess tw-bg-greend4 tw-p-2.5"
  >
    <p class="tw-p-4 tw-pb-0 tw-font-bold">{{ $t('common.newVersion') }}</p>
    <div class="tw-flex tw-justify-around">
      <UIButton
        @click="rejectUpdate"
        type="secondary"
        class="!tw-bg-white"
        :label="$t('common.discardChanges')"
      ></UIButton>
      <UIButton
        @click="reloadPage"
        type="primary"
        :label="$t('common.loadVersion')"
      ></UIButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import type { Nullable } from '@/types/Nullable'
import UIButton from '@/components/UI/UIButton.vue'

@Component({
  components: { UIButton },
})
export default class NewVersionNotification extends Vue {
  public showUpdateNotification: boolean = false
  //eslint-disable-next-line
  public existVersion: Nullable<string> = null
  private notificationKey: string = 'update-notification'

  public mounted(): void {
    this.startWatchingManifest()
  }

  private startWatchingManifest(): void {
    const manifestUrl = '/.version'
    let initialCheck = true

    setInterval(async () => {
      try {
        await fetch(manifestUrl).then(async (response) => {
          await response.text().then((newVersion) => {
            const version = newVersion.trim()
            const storedVersion = localStorage.getItem(this.notificationKey)
            if (initialCheck) {
              this.existVersion = version
              initialCheck = false
              return
            }

            if (storedVersion && storedVersion !== version) {
              this.showUpdateNotification = true
            }

            if (!storedVersion || storedVersion !== version) {
              this.existVersion = version
              localStorage.setItem(this.notificationKey, version)
            }
          })
        })
      } catch (error) {
        console.error('Error fetching manifest:', error)
      }
    }, 15000)
  }

  public rejectUpdate(): void {
    this.showUpdateNotification = false
    if (this.existVersion) {
      localStorage.setItem(this.notificationKey, this.existVersion)
    }
  }

  public reloadPage(): void {
    localStorage.setItem(this.notificationKey, this.existVersion)
    window.location.reload(true)
  }
}
</script>
