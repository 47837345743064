import { errorsService } from '@/main'
import client from '@/services/client'
import { errorsEnum } from '@/types/ErrorTypes'
import type { IPermissionGet } from '@/types/PermissionsTypes'
import type { IUser, IUpdatePayload } from '@/types/UserTypes'
import { isAxiosError } from 'axios'
import type { IProfileSettingsChangePasswdForm } from '@/types/ProfileTypes'
import type { Nullable } from '@/types/Nullable'
import type { IRole, IUpdateUserRolesResult } from '@/types/RolesTypes'

export interface IContextResponse {
  id: number
  user_id: number
  unit_id: number
  role_id: number
}

export interface IContextGetResponse {
  id: number
  user_id: number
  unit_id: Nullable<number>
  role_id: Nullable<number>
}

export interface IUpdateRolePayload {
  roleArr: number[]
  categoryNumbersArr: number[]
  companyUnitId: number
}

// TEMP interface for IUnit
export interface IUnit {
  id: number
  name: string
}

class UsersManagementRepository {
  public async getOneUser(id: string): Promise<IUser> {
    return await client
      .get<IUser>(`users/${id}`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetOneUser,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getMultipleUsers(): Promise<IUser[]> {
    return await client
      .get<IUser[]>('users')
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetMultipleUsers,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateActiveStatus(
    id: number,
    isActive: boolean
  ): Promise<void> {
    type IActiveStatusPayload = {
      activeStatus: 0 | 1
    }

    const payload: IActiveStatusPayload = {
      activeStatus: isActive ? 1 : 0,
    }

    await client.patch(`users/${id}/active`, payload).catch((error) => {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.UpdateActiveStatus,
          response: error.response,
        })
      }

      throw error
    })
  }

  public async getUserUnits(userId: string | number): Promise<IUnit[]> {
    return await client
      .get<IUnit[]>(`users/${userId}/units`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetUserUnits,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateUserUnits(
    userId: string | number,
    payload: number[]
  ): Promise<void> {
    await client
      .patch(`users/${userId}/units`, {
        unitsArr: payload,
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateUserUnits,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getUserRoles(
    userId: string | number,
    unitId: string | number
  ): Promise<IRole[]> {
    const filterBy = [{ company_unit_id: unitId }]
    const acc = [filterBy]
    const payload = new URLSearchParams()
    payload.append('filterBy', JSON.stringify(acc))

    return await client
      .get<IRole[]>(`users/${userId}/roles`, {
        params: payload,
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetUserRoles,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateUserRoles(
    userId: string | number,
    payload: IUpdateRolePayload
  ): Promise<IUpdateUserRolesResult> {
    return await client
      .patch<IUpdateUserRolesResult>(`users/${userId}/roles`, payload)
      .then((result) => {
        return result.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateUserRoles,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getUserPermissions(payload: {
    userId: string | number
    companyUnitId: string | number
    roleId: string | number
  }): Promise<IPermissionGet> {
    const { userId, companyUnitId, roleId } = payload
    return await client
      .get<IPermissionGet>(`users/${userId}/permissions`, {
        params: {
          companyUnitId: companyUnitId,
          roleId: roleId,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetUserPermissions,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateUserPermissions(
    userId: string | number,
    payload: IUpdatePayload
  ): Promise<void> {
    return await client
      .patch(`users/${userId}/permissions`, payload)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateUserPermissions,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getUserContext(
    userId: string | number
  ): Promise<IContextGetResponse> {
    return await client
      .get<IContextGetResponse>(`users/${userId}/context`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetUserContext,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateUserContext(
    userId: string | number,
    payload: { companyContext?: number; roleContext?: number }
  ): Promise<IContextResponse> {
    return await client
      .patch<IContextResponse>(`users/${userId}/context`, payload)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateUserPermissions,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updatePassword(
    userId: string | number,
    payload: IProfileSettingsChangePasswdForm
  ): Promise<void> {
    return await client
      .patch(`users/profile/${userId}/settings/password`, payload)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdatePassword,
            response: error.response,
          })
        }

        throw error
      })
  }
}

export default new UsersManagementRepository()
