import { Store, Pinia } from 'pinia-class-component'
import type {
  IAssignment,
  IFilters,
  IStatus,
  ITaskAddForm,
  IType,
  ITaskActionTakeOver,
  ITaskActionDelegate,
  ITaskActionClose,
  INotification,
  INotificationSetting,
} from '@/types/TasksTypes'
import TaskRepository from '@/repositories/TaskRepository'
import type { Nullable } from '@/types/Nullable'

interface ITaskStore {
  tasks: IAssignment[]
  task: Nullable<IAssignment>
  statuses: IStatus[]
  types: IType[]
  notifications: INotification[]
  notificationsSettings: INotificationSetting[]
  notificationSetting: Nullable<INotificationSetting>
  filters: Nullable<IFilters>
  isLoading: boolean
  isError: boolean
}

@Store
export default class TaskService extends Pinia {
  public store: ITaskStore = {
    tasks: [],
    task: null,
    statuses: [],
    types: [],
    notifications: [],
    notificationsSettings: [],
    notificationSetting: null,
    filters: null,
    isLoading: false,
    isError: false,
  }

  public get tasks(): IAssignment[] {
    return this.store.tasks
  }

  public get task(): Nullable<IAssignment> {
    return this.store.task
  }

  public get notifications(): IAssignment[] {
    return this.store.notifications
  }

  public get notificationsSettings(): INotificationSetting[] {
    return this.store.notificationsSettings
  }

  public get notificationSetting(): Nullable<INotificationSetting> {
    return this.store.notificationSetting
  }

  public get filters(): Nullable<IFilters> {
    return this.store.filters
  }

  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public get statuses(): IStatus[] {
    return this.store.statuses
  }

  public get types(): IType[] {
    return this.store.types
  }

  public async loadTasks(filters?: string): Promise<void> {
    this.store.isLoading = true
    await TaskRepository.getTasks(filters)
      .then((response) => {
        this.store.tasks = response
      })
      .catch(() => {
        this.store.tasks = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadStatuses(): Promise<void> {
    this.store.isLoading = true
    await TaskRepository.getStatuses()
      .then((response) => {
        this.store.statuses = response
      })
      .catch(() => {
        this.store.statuses = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadTypes(): Promise<void> {
    this.store.isLoading = true
    await TaskRepository.getTypes()
      .then((response) => {
        this.store.types = response
      })
      .catch(() => {
        this.store.types = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addTask(task: ITaskAddForm): Promise<void> {
    await TaskRepository.addTask(task)
      .then(() => {
        this.loadTasks()
      })
      .catch(() => {
        this.store.isError = true
        throw new Error('Error while adding task')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async getTask(id: number): Promise<void> {
    await TaskRepository.getTask(id)
      .then((task: IAssignment) => {
        this.store.task = task
      })
      .catch(() => {
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadNotifications(): Promise<void> {
    await TaskRepository.getNotifications()
      .then((notifications: INotification[]) => {
        this.store.notifications = notifications
      })
      .catch(() => {
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateAction(
    taskId: number,
    action: ITaskActionTakeOver | ITaskActionDelegate | ITaskActionClose
  ): Promise<void> {
    this.store.isLoading = true
    await TaskRepository.updateAction(taskId, action)
      .then(() => {
        this.loadTasks()
      })
      .catch(() => {
        this.store.isError = true
        throw new Error('Error while updating action')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadNotificationsSettings(): Promise<void> {
    await TaskRepository.getNotificationsSettings()
      .then((notificationsSettings: INotificationSetting[]) => {
        this.store.notificationsSettings = notificationsSettings
      })
      .catch(() => {
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addNotificationSetting(
    notificationSettingForm: INotificationSetting
  ): Promise<void> {
    await TaskRepository.addNotificationSetting(notificationSettingForm)
      .then(() => {
        this.loadNotificationsSettings()
      })
      .catch(() => {
        this.store.isError = true
        throw new Error('Error while adding notification setting')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadNotificationSetting(id: number): Promise<void> {
    await TaskRepository.getNotificationSetting(id)
      .then((notificationSetting: INotificationSetting) => {
        this.store.notificationSetting = notificationSetting
      })
      .catch(() => {
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateNotificationSetting(
    id: number,
    notificationSettingForm: INotificationSetting
  ): Promise<void> {
    this.store.isLoading = true
    await TaskRepository.updateNotificationSetting(id, notificationSettingForm)
      .then(() => {
        this.loadNotificationsSettings()
      })
      .catch(() => {
        this.store.isError = true
        throw new Error('Error while updating notification setting')
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }
}
