<template>
  <div
    class="c-dashboardCounter time tw-mb-0 tw-flex tw-items-center tw-justify-center tw-gap-4 tw-text-xs"
  >
    <div class="tw-flex tw-items-center tw-gap-2">
      <span>
        {{ $t('common.headerTime') }}
      </span>
      <span class="tw-text-sm tw-font-bold tw-tracking-wider">{{ timer }}</span>
    </div>

    <UIButton
      type=""
      size="medium"
      button-class="tw-text-black !tw-mb-0"
      :disabled="isReauthInProgress"
      @click="extendSession"
    >
      <template #left>
        <div
          class="hover:tw-opacity-50"
          :class="isReauthInProgress && 'tw-animate-spin'"
        >
          <font-awesome-icon
            icon="icon fa-solid fa-arrows-rotate"
            class="tw-text-lg"
          />
        </div>
      </template>
    </UIButton>
  </div>
  <SessionTimeoutModal
    :timer="timer"
    @logout="handleSessionTimeout"
    @extendsession="extendSession"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { environmentsManager, userService } from '@/main'
import SessionTimeoutModal from '@/components/layouts/dashboardLayout/SessionTimeoutModal.vue'
import { expiredSessionModalService } from '@/main'
import { routeNames } from '@/router/types'
import UIButton from '@/components/UI/UIButton.vue'
import { counterTimeFormat } from '@/helpers/getDateAndHourFormat'
import { LocalStorageKeys } from '@/types/LocalStorageKeys'

@Component({
  components: { UIButton, SessionTimeoutModal },
})
export default class DashboardCounter extends Vue {
  public timer: string = ''
  public modalShown: boolean = false
  public isReauthInProgress: boolean = false

  public get countdownStartInSeconds(): number {
    return Number(environmentsManager.MODAL_SHOW_LIFETIME) * 60
  }

  public async handleLogout(): Promise<void> {
    if (userService.isLoggedIn) {
      await userService.logout()
    }
    expiredSessionModalService.close()
    this.modalShown = false
    this.$router.push({ name: routeNames.Logout })
  }

  public handleSessionTimeout(): void {
    this.handleLogout()
  }

  public async extendSession(): Promise<void> {
    try {
      this.isReauthInProgress = true
      await userService.reauth()
    } catch (e) {
      console.error(e)
    } finally {
      this.isReauthInProgress = false
      this.modalShown = false
    }
  }

  public get showModal(): boolean {
    return expiredSessionModalService.isOpen
  }

  public getTimeCookie(): string | null {
    // const value = `; ${document.cookie}`
    // const parts = value.split(`; counter=`)
    //
    // if (parts.length === 2) {
    //   const item = parts.pop()?.split(';').shift()
    //   if (!item) return null
    //   return item
    // }
    const time = localStorage.getItem(LocalStorageKeys.COUNTER)
    if (time) {
      return time
    }
    return null
  }

  public getStartTime(): number {
    const cookieTime = this.getTimeCookie()
    return cookieTime ? Number(cookieTime) : 0
  }

  public get sessionMinutes(): number {
    return Number(environmentsManager.SESSION_LIFETIME)
  }

  public getTargetDate(): number {
    const date = new Date(this.getStartTime())
    return date.setMinutes(date.getMinutes() + this.sessionMinutes)
  }

  public getTime(): number {
    const currentDate = new Date()
    return this.getTargetDate() - Number(currentDate)
  }

  public handleCalcTime(interval?: number): void {
    const timeLeft = this.getTime()
    const isMinuteLeft = timeLeft <= this.countdownStartInSeconds * 1000
    const shouldShowModal = isMinuteLeft && !this.modalShown
    if (timeLeft <= 0) {
      this.handleSessionTimeout()
      if (interval) clearInterval(interval)
      return
    } else if (shouldShowModal) {
      expiredSessionModalService.open()
      this.modalShown = true
    }
    this.timer = counterTimeFormat(timeLeft)
  }

  public mounted(): void {
    this.handleCalcTime()
    const interval = setInterval(() => {
      this.handleCalcTime(interval)
    }, 1000)
  }
}
</script>
